import { api } from "../../core/api";
let mockData = [
  { id: 1, description: "Test1" },
  { id: 2, description: "Test2" },
  { id: 3, description: "Test3" },
];
export default {
  async getData() {
    // return await api.get("/api/CabinetAbove/" );
    return mockData;
  },
  async save(data) {
    return await api.post("/api/CabinetAbove", data);
  },
  async update(data) {
    return await api.put("/api/CabinetAbove", data);
  },
  async delete(id) {
    return await api.delete("/api/CabinetAbove/" + id);
  },
  async get(id) {
    return mockData.filter((x) => x.id == id)[0];
    // return await api.get("/api/CabinetAbove/" + id);
  },
};
